import React, { useState, useRef } from 'react';
import { trackNameOnly } from "../utils";
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';
import PropTypes from "prop-types";
import './AudioPlayer.css';

const AudioPlayer = ({ tracks }) => {
  const audioRef = useRef(null);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [activeTrack, setActiveTrack] = useState(null);
  const [isPlayClicked, setIsPlayClicked] = useState(false);
  const [isPauseClicked, setIsPauseClicked] = useState(false);

  const trackNameList = trackNameOnly(tracks);

  const playTrack = () => {
    audioRef.current.play();
  };

  const pauseTrack = () => {
    audioRef.current.pause();
  };

  const selectTrack = (index) => {
    setCurrentTrackIndex(index);
    setActiveTrack(index);
  };

  const handleTrackEnded = () => {
    if (currentTrackIndex < tracks.length - 1) {
      const nextIndex = currentTrackIndex + 1;
      setCurrentTrackIndex(nextIndex);
      selectTrack(nextIndex);
      setTimeout(() => {
        setActiveTrack(nextIndex);
        setIsPlayClicked(true);
        playTrack();
      }, 100);    
    }
    setIsPlayClicked(false);
    setIsPauseClicked(false);
    setActiveTrack(null);
  };


  return (
    <div>
      <audio
        ref={audioRef}
        src={tracks[currentTrackIndex]}
        onEnded={handleTrackEnded}
      ></audio>
      <Stack direction="row" spacing={5} justifyContent='center' m={2}>
        <Button
          sx={{
            borderRadius: 5,
            borderColor: '#b04672',
            width: '100px',
            maxWidth: '100%',
            '&:hover': {
              backgroundColor: '#b04672',
              color: 'white',
            },
            '&:visited': {
              backgroundColor: '#e0d3da',
              color: 'white',
            },
          }}
          variant={isPlayClicked ? 'contained' : 'outlined'} 
          size='large'         
          className='audioPlayerControl' 
          onClick={() => {
            setIsPlayClicked(true);
            setIsPauseClicked(false);
            playTrack();
          }}
        >
          Play
        </Button>
        <Button 
          sx={{
            borderRadius: 5,
            borderColor: '#b04672',
            width: '100px',
            maxWidth: '100%',
            '&:hover': {
              backgroundColor: '#b04672',
              color: 'white',
            },
          }}
          variant={isPauseClicked ? 'contained' : 'outlined'} 
          size='large'    
          fullWidth              
          className='audioPlayerControl' 
          onClick={() => {
            setIsPauseClicked(true);
            setIsPlayClicked(false);
            pauseTrack();
          }}
        >
          Pause
        </Button>
      </Stack>
      <br />
      <div className='tinyWrapperFlex'>
        {tracks.map((track, index) => (
          <Button
            sx={{
              borderRadius: 5,
              borderColor: '#f0f3f4',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
              backgroundColor: activeTrack === index ? '#b04672' : "#f0f3f4",
              color: activeTrack === index ? 'white' : 'black',
              '&:hover': {
                backgroundColor: '#b04672',
                color: 'white',
              },
            }}
            variant={activeTrack === currentTrackIndex ? 'contained' : 'outlined'}
            className='audioPlayerTrack'
            key={index}
            onClick={() => {
              selectTrack(index)
              setIsPauseClicked(false);
              setIsPlayClicked(false);
            }}
          >
            {trackNameList[index]}
          </Button>
        ))}
      </div>
    </div>
  );
};

AudioPlayer.propTypes = {
  tracks: PropTypes.array
}

export default AudioPlayer;