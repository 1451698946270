import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import "./SuccessModal.css";


function SuccessModal(props) {

  const [open, setOpen] = useState(false);
  
  useEffect(() => {
    setOpen(props.showModal);
		},[props]
	);

  const handleClose = () => {
    setOpen(false)
    window.scrollTo(0, 0);
    window.location.reload(true);
  }

  return (
    <div> 
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        >
          <Fade in={open}>
            <Box className='modal' sx={{ bgcolor: '#b04672'}}>
              <CloseRoundedIcon onClick={handleClose} />
              <Typography sx={{ fontSize: '22px' }} id="modal-modal-title" variant="h6" component="h2">
                SUCCESS!
              </Typography>
            </Box>
          </Fade>
        </Backdrop>
      </Modal>
    </div>
  );
}

SuccessModal.propTypes = {
  showModal: PropTypes.bool
}

export default SuccessModal;