import React from 'react';
import YoutubeEmbed from "../components/YoutubeEmbed";
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import FloatingActionButton from "../components/FloatingActionButton";
import './Home.css';


function Home() {

  return (
		<>
      <div className='obitWrapper'>
        <br/>
        <br/>
        <br/>
        <img className="obitPic" src='./images/JuneStephenPic.png' alt="June Stephen" />
        <br/>
        <h1>June Cecilia Stephen</h1>
        <h2>1944 &ndash; 2023</h2>
        <p>
          June Cecilia Stephen (née Bunyan) took her first breath on April
          23, 1944, and passed away peacefully on March 28, 2023, at Santa
          Monica-UCLA Hospital. She was preceded in death by her parents
          Hector J. Bunyan, Sr. and Winifred Bunyan; her brother Frank
          Bunyan; and her sister-in-law Pauline Bunyan (Fred). She is
          survived by her husband of 53 years, Randolph “Randy” Stephen;
          her daughters Shani Childress and Anika Stephen Wilbur
          (Benjamin); her brothers Wendell Bunyan (Cheryl), Hector J.
          Bunyan, Jr., Fred Bunyan, and Keith Bunyan (Eunita); her
          grandchildren Nathan and Malia Childress, and many cousins,
          nieces, and nephews.
        </p>
        <p>
          Born in Georgetown, Guyana, South America, June was the eldest
          of six children and the only girl among five brothers. She
          attended Broad Street Government School, followed by St.
          Joseph’s High School, where she successfully obtained her
          General Certificate of Education in 1961. In addition to her
          academic studies, June also participated in many musical
          activities. She started her piano training at the tender age of
          six, and following her graduation from St. Joseph’s, she
          continued to study piano. She subsequently attained a high level
          of success as a pianist under the tutelage of Mrs. Lynette
          Katchay-Bumbery and Mrs. Jessie Heath, both of Guyana. She was
          also a member and accompanist of the Guyana Woodside Choir under
          the direction of Mrs. Ruby McGregor. And as a result of her
          exceptional musical talent, she was awarded first place in the
          Adult Solo Instrument category when she competed on the piano in
          the 1967 Guyana Music Festival.
        </p>
        <p>
          June left Guyana in early 1969 to join her then-fiancé Randy
          Stephen in Los Angeles, California, and they were married in May
          of the same year. Randy was not the only one awaiting her
          arrival in California, as California Lutheran College had
          offered June a position prior to her arrival in the United
          States. This offer was due in part to June’s impressive
          shorthand writing and typing skills; she was known to type as
          fast as 100 words per minute. Following her time at Cal
          Lutheran, June embarked upon a career in Human Resources,
          beginning with PaperMate from 1970 to 1975.
        </p>
        <p>
          In 1975, June, Randy, and their first daughter, Shani, returned
          to Georgetown, Guyana, for three years. While there, she worked
          for Demerara Life Insurance Company and United Nations
          Development Program. She also rejoined Woodside Choir, under the
          direction of Mr. Billy Pilgrim, and participated in many
          memorable concerts. Among them were her solo vocal performance
          during the 1976 Woodside Christmas Concert at Company Path in
          Georgetown, and the group’s 1977 performance at Guyana’s
          National Cultural Center. A highlight of the latter was June’s
          role as one of two accompanists in a beautiful choral
          arrangement featuring two pianos.
        </p>
        <p>
          In 1978, June and family returned to Southern California,
          settling in Santa Monica, and a few years later, their second
          daughter, Anika, was born. June continued her career in Human
          Resources, working for Delphi Communications, National Medical
          Enterprises, Salick Healthcare, and SD&A Teleservices, Inc. She
          retired from SD&A in 2018, and that allowed her to spend more
          time on the activities she loved: reading her Bible and
          observing daily devotions, delighting many by singing and
          playing the piano, practicing yoga, speed-walking, sewing,
          crocheting, baking, and playing word games.
        </p>
        <p>
          To know June was to know her faith. She gave thanks to God for
          all the blessings in her life and found peace in the assurance
          that God is in control. June used her musical talents to give
          glory to God, singing in the choir and playing the piano at
          First A.M.E. Church By-the-Sea and Mt. Olive Lutheran Church
          over the years. In recent months, she played pre-service music
          for those persons arriving ahead of Mt. Olive’s 9:00 am Sunday
          service. June truly looked forward to this pre-service time at
          the piano, making it a point to be there as often as she could,
          health permitting.
        </p>
        <p>
          Possessing a loving, compassionate, generous, and gracious
          spirit, June greeted everyone she met with a cheerful smile and
          a kind word. She was a loyal friend who took great joy in being
          of service to others. She will be remembered for her outgoing
          nature, along with her sense of humor and sharp wit. She always
          made time to recognize friends and family members, often calling
          them on their birthdays to regale them with a rousing rendition
          of the “Happy Birthday” song.
        </p>
        <p>
          June was loved by all who knew her, and she will be greatly
          missed!
        </p>
        <br />
        <Divider />
        <div className="inLieuWrapperFlex">
          <span>
            In lieu of flowers, donations in June’s memory can be made to
            the Multiple Myeloma Research Foundation (MMRF)
            or the Leukemia & Lymphoma Society (LLS).
          </span>
          <div className='tinyWrapperFlex'>
            <div className='donationButton'>
            <Link className="donationLink" to='https://www.themmrf.org' target="_blank">
              <Button sx={{ width: '200px' }} type='button' size='large' variant="contained" disableElevation>Donate to MMRF</Button>
            </Link>
            </div>
            <div className='donationButton'>
            <Link className="donationLink" to='https://www.lls.org' target="_blank">
              <Button sx={{ width: '200px' }} type='button' size='large' variant="contained" disableElevation>Donate to LLS</Button>
            </Link>
            </div>
          </div>
        </div>
        <Divider />
        <div className="guyanaTvGrid">
          <YoutubeEmbed className='iframe' embedId="3snYt6R0Wls" />
        </div>
      </div>
      <div className="floatingButtonFlex">
        <div className="floatingButton">
          <FloatingActionButton showBelow={400}/>
        </div>
      </div>
		</>
  );
}

export default Home;