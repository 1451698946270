import "./WelcomeFooter.css";

const WelcomeFooter = () => {
  return (
    <footer className="welcomeFooter">
      <h3 className="footerText">Anika Stephen Wilbur ©️ 2023 {'\u2022'} Made with 💗</h3>
    </footer>
  )
};

export default WelcomeFooter;