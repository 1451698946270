import React from 'react';
import PropTypes from "prop-types";
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import './PhotoCard.css';


function PhotoCard(props) {

  // const [reload, setReload] = useState(false);

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth < 750) {
  //       setReload(true);
  //     } else if (window.innerWidth >= 750 && shouldReload) {
  //       setShouldReload(false);
  //     }
  //   };

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, [shouldReload]);

  // useEffect(() => {
  //   if (reload) {
  //     window.location.reload();
  //   }
  // }, [reload]);

  return (
		<Card
			raised
			sx={{
				margin: "auto",
				width: "fit-content",
				maxWidth: { xs: "100%", sm: '1200px' },
				maxHeight: "100%",
				border: "1px solid lightgrey",
			}}
		>
			<Grid alignItems='center' container>
				<Grid item xs={12} sm={6}>
          <div className="photoFlex">
            <CardMedia
              sx={{
                maxWidth: 700,
                maxHeight: "100%",
                borderRadius: 1,
              }}
              component="img"
              image={props.url}
              onLoad={props.onLoad}
            />
          </div>
				</Grid>
				<Grid item xs={12} sm={6}>
          <div className="captionFlex">
            <CardContent
              sx={{
                paddingBottom: "16px !important",
              }}
            >
              <Typography
                className="caption"
                variant="body1"
                sx={{
                  paddingLeft: "1em",
                  paddingRight: "1em",
                  fontSize: "24px",
                  fontFamily: "Cormorant",
                  fontWeight: "bolder",
                  whiteSpace: "pre-line"
                }}
              >
                {props.caption}
              </Typography>
              <br />
              <Typography
                className="addedBy"
                variant="body2"
                sx={{
                  paddingLeft: "1em",
                  paddingRight: "1em",
                  fontSize: "16px",
                  fontFamily: "Cormorant",
                  fontWeight: "bolder",
                }}
              >
                {/* Added by {props.uploader} */}
              </Typography>
            </CardContent>
          </div>
				</Grid>
			</Grid>
		</Card>
  );
}

PhotoCard.propTypes = {
  image: PropTypes.string,
  caption: PropTypes.string,
  uploader: PropTypes.string,
  onLoad: PropTypes.func
}

export default PhotoCard;