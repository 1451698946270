import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MemoryCard from "../components/MemoryCard";
import Pagination from "@mui/material/Pagination";
import SuccessModal from "../components/SuccessModal";
import "./Memories.css";

const kBaseUrl = "https://be-june-website.herokuapp.com";

const kDefaultFormState = {
	memory: "",
	uploader: "",
};

function Memories() {

  const [memoryLoaded, setMemoryLoaded] = useState(false);
	const [addMemory, setAddMemory] = useState(false);
	const [memoriesData, setMemoriesData] = useState([]);
	const [tempNewMemory, setTempNewMemory] = useState(kDefaultFormState);
	const [tempMemory, setTempMemory] = useState("");
	const [tempUploader, setTempUploader] = useState("");
	const [isValid, setIsValid] = useState(false);
	const [memoryIndex, setMemoryIndex] = useState(0);
	const [cardList, setCardList] = useState(0);
	const [cardHeight, setCardHeight] = useState(0);
	const [page, setPage] = useState(1);
	const [showModal, setShowModal] = useState(false);

	const uploadSection = useRef();
	const nameRef = useRef();
	const memoryRef = useRef();
	const cardRefs = useRef({});

	const getCardHeight = (index) => {
		setCardHeight(cardList[index].clientHeight);
	};

	const validate = () => {
		if (tempUploader && tempMemory) {
			setIsValid(true);
		}
	};

  const handleCardRendered = (index) => {
    if (cardRefs.current[index]) {
      setMemoryLoaded(true);
    }
  };

	useEffect(() => {
		axios
			.get(`${kBaseUrl}/memories`)
			.then((responses) => {
				setMemoriesData(responses.data);
				setCardList(cardRefs.current);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	useLayoutEffect(() => {
		const timer = setTimeout(() => {
			setCardHeight(cardRefs.current[0].clientHeight);
		}, 200);
		return () => clearTimeout(timer);
	}, [memoryLoaded]);

	const handleJumpToUpload = (event) => {
		setAddMemory(true);
    setTimeout(() => {
			uploadSection.current.scrollIntoView({
				behavior: "smooth",
			});
		}, 100);
	};

	const handleTempMemory = (event) => {
		let newMemory = event.target.value;
		setTempMemory(newMemory);
		setTempNewMemory({ ...tempNewMemory, memory: newMemory });
		validate();
	};

	const handleUploadedBy = (event) => {
		let uploaderName = event.target.value;
		setTempUploader(uploaderName);
		setTempNewMemory({ ...tempNewMemory, uploader: uploaderName });
		validate();
	};

	const memoriesCarousel = memoriesData.map((memory, n) => {
		let position =
			n > memoryIndex
				? "nextCard"
				: n === memoryIndex
				? "activeCard"
				: "prevCard";
		return (
			<li key={memory.id}>
				<div
					className={`memoryCard ${position}`}
					ref={(ref) => (cardRefs.current[n] = ref)}
				>
					<MemoryCard
						memory={memory.memory}
						uploader={memory.uploader}
						date={memory.date}
            onCardRendered={() => handleCardRendered(n)}
					/>
				</div>
			</li>
		);
	});

	const changeMemory = (event, value) => {
		const page = value;
		setPage(page);
		getCardHeight(page - 1);
		setMemoryIndex(page - 1);
	};

	const addNewMemoryToApi = (newMemory) => {
		const requestBody = newMemory;

		axios
			.post(`${kBaseUrl}/memories`, requestBody)
			.then(() => {
				console.log("New memory added: success");
			})
			.catch((error) => {
				console.log(error);
			});
		setAddMemory(false);
		setShowModal(true);
	};

	const onFormSubmit = (event) => {
		event.preventDefault();
		addNewMemoryToApi(tempNewMemory);
	};


	return (
		<>
			<h1>Memories of June</h1>
      <div className="tinyWrapper">
        {!addMemory && (
          <Button
            sx={{
              height: 50,
              fontSize: "22px",
            }}
            variant="contained"
            disableElevation
            onClick={handleJumpToUpload}
          >
            Add a Memory
          </Button>
        )}
      </div>
      <br />
      <br />
      <br />
      <div className="paginationFlex">
        <Pagination
          count={memoriesData.length}
          siblingCount={0}
          showFirstButton
          showLastButton
          page={page}
          onChange={changeMemory}
        />
      </div>
      <br />
      <div className="cardContainerFlex">
        <ul
          className="carousel"
          style={{ height: `${cardHeight + 70}px` }}
        >
          {memoriesCarousel}
        </ul>
      </div>
			<div ref={uploadSection}>
				{addMemory && (
					<>
						<div className="addRememberanceGrid">
							<TextField
								id="outlined-basic"
								label="Your name"
								variant="outlined"
								required
								fullWidth
								ref={nameRef}
								onChange={handleUploadedBy}
							></TextField>
							<br />
							<TextField
								id="outlined-basic"
								label="Share a memory..."
								variant="outlined"
								multiline
								required
								fullWidth
								minRows={8}
								ref={memoryRef}
								onChange={handleTempMemory}
							></TextField>
						</div>
						<br />
						<br />
						<div className="tinyWrapper">
							<Button
								sx={{
									width: {
										sm: 400,
										md: 500,
										lg: 600,
									},
									height: 50,
									fontSize: "22px",
								}}
								className="submit"
								variant="contained"
								disableElevation
								disabled={!isValid}
								onClick={onFormSubmit}
							>
								Submit
							</Button>
						</div>
						<br />
						<br />
						<br />
						<br />
					</>
				)}
				{showModal && <SuccessModal showModal={showModal} />}
			</div>
		</>
	);
}

export default Memories;
