import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import axios from "axios";
// import { useDropzone } from 'react-dropzone';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import PhotoCard from "../components/PhotoCard";
import Pagination from "@mui/material/Pagination";
import FormHelperText from "@mui/material/FormHelperText";
// import Grid from '@mui/material/Grid';
import Stack from "@mui/material/Stack";
import ImagePreview from "../components/ImagePreview";
import SuccessModal from "../components/SuccessModal";
import "./Photos.css";

const kBaseUrl = "https://be-june-website.herokuapp.com";

const kDefaultFormState = {
	url: "",
	caption: "",
	uploader: "",
};

function Photos({ onDrop }) {
	const [imageLoaded, setImageLoaded] = useState(false);
	const [addPhoto, setAddPhoto] = useState(false);
	const [photosData, setPhotosData] = useState([]);
	const [tempNewPhoto, setTempNewPhoto] = useState(kDefaultFormState);
	const [tempCaption, setTempCaption] = useState("");
	const [tempUploader, setTempUploader] = useState("");
	const [isValid, setIsValid] = useState(false);
	const [image, setImage] = useState("");
	const [imageSaved, setImageSaved] = useState(false);
	const [photoIndex, setPhotoIndex] = useState(0);
	const [cardList, setCardList] = useState(0);
	const [cardHeight, setCardHeight] = useState(0);
	const [page, setPage] = useState(1);
	const [showModal, setShowModal] = useState(false);

	const uploadSection = useRef();
	const confirmUploadSection = useRef();
	const captionSection = useRef();
	const photoRef = useRef();
	const nameRef = useRef();
	const captionRef = useRef();
	const cardRefs = useRef({});

	const getCardHeight = (index) => {
		setCardHeight(cardList[index].clientHeight);
	};

	const validate = () => {
		if (tempUploader && tempCaption) {
			setIsValid(true);
		}
	};

	const onLoad = () => {
		setImageLoaded(true);
	};

	useEffect(
		() => {
			// files.forEach(file => URL.revokeObjectURL(file.preview));
			axios
				.get(`${kBaseUrl}/images`)
				.then((responses) => {
					setPhotosData(responses.data);
					setCardList(cardRefs.current);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		[
			/*files*/
		]
	);

	useLayoutEffect(() => {
		const timer = setTimeout(() => {
			setCardHeight(cardRefs.current[0].clientHeight);
		}, 200);
		return () => clearTimeout(timer);
	}, [imageLoaded]);

	// send photos to cloud storage
	const handleImageUpload = () => {
		const imageCloudData = new FormData();
		imageCloudData.append("image", image);
		axios
			.post(`${kBaseUrl}/images/upload`, imageCloudData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				console.log("Image URL: success");
				setTempNewPhoto({ ...tempNewPhoto, url: response.data.url });
				setImageSaved(true);
				scrollToAddCaption();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const resetImage = (event) => {
		setImage("");
		setImageSaved(false);
	};

	const handleJumpToUpload = (event) => {
		setAddPhoto(true);
		setTimeout(() => {
			uploadSection.current.scrollIntoView({
				behavior: "smooth",
				block: "end",
				inline: "nearest",
			});
		}, 100);
	};

	const handleJumpToConfirmUpload = (event) => {
		setTimeout(() => {
			confirmUploadSection.current.scrollIntoView({
				behavior: "smooth",
				block: "end",
				inline: "nearest",
			});
		}, 100);
	};

	const scrollToAddCaption = (event) => {
		setTimeout(() => {
			captionSection.current.scrollIntoView({
				behavior: "smooth",
			});
		}, 50);
	};

	const handleCaption = (event) => {
		let newCaption = event.target.value;
		setTempCaption(newCaption);
		setTempNewPhoto({ ...tempNewPhoto, caption: newCaption });
		validate();
	};

	const handleUploadedBy = (event) => {
		let uploaderName = event.target.value;
		setTempUploader(uploaderName);
		setTempNewPhoto({ ...tempNewPhoto, uploader: uploaderName });
		validate();
	};

	const changePhoto = (event, value) => {
		const page = value;
		setPage(page);
		getCardHeight(page - 1);
		setPhotoIndex(page - 1);
	};

	const photosCarousel = photosData.map((photo, n) => {
		let position =
			n > photoIndex
				? "nextCard"
				: n === photoIndex
				? "activeCard"
				: "prevCard";
		return (
			<li key={photo.id}>
				<div
					className={`photoCard ${position}`}
					ref={(ref) => (cardRefs.current[n] = ref)}
				>
					<PhotoCard
						url={photo.url}
						caption={photo.caption}
						uploader={photo.uploader}
						onLoad={onLoad}
					></PhotoCard>
				</div>
			</li>
		);
	});

	const addNewPhotoToApi = (newPhoto) => {
		const requestBody = newPhoto;

		axios
			.post(`${kBaseUrl}/images`, requestBody)
			.then(() => {
				console.log("New image(s) added: success");
			})
			.catch((error) => {
				console.log(error);
			});
		setAddPhoto(false);
		setShowModal(true);
	};

	const onFormSubmit = (event) => {
		event.preventDefault();
		addNewPhotoToApi(tempNewPhoto);
	};

	return (
		<>
			<h1>Photos of June</h1>
			<div className="tinyWrapper">
				{!addPhoto && (
					<Button
						sx={{
							height: 50,
							fontSize: "22px",
						}}
						variant="contained"
						disableElevation
						onClick={handleJumpToUpload}
					>
						Add a Photo
					</Button>
				)}
			</div>
			<br />
			<br />
			<br />
			<div className="paginationFlex">
				<Pagination
					sx={{
						"& .MuiPaginationItem-root": {
							fontSize: {
								xs: "small",
								sm: "medium",
								md: "large",
							},
						},
					}}
					count={photosData.length}
					siblingCount={0}
					showFirstButton
					showLastButton
					page={page}
					onChange={changePhoto}
				/>
			</div>
			<br />
			<div className="cardContainerFlex">
				<ul
					className="carousel"
					style={{ height: `${cardHeight + 70}px` }}
				>
					{photosCarousel}
				</ul>
			</div>
			<div ref={uploadSection}>
				{addPhoto && (
					<>
						{!image && (
							<div className="tinyWrapper">
								<Button
									sx={{
										height: 50,
										fontSize: "22px",
									}}
									variant="contained"
									component="label"
									size="large"
									disableElevation
								>
									Choose a Photo
									<input
										hidden
										accept="image/*"
										multiple
										type="file"
										id="imageFile"
										name="imageFile"
										ref={photoRef}
										onChange={(event) => {
											setImage(event.target.files[0]);
											handleJumpToConfirmUpload();
										}}
									/>
								</Button>
								<br />
								<br />
								<br />
								<br />
							</div>
						)}
						{image && !imageSaved && (
							<>
								<div className="choosePhotoFlex">
									<div>
										<p>
											Click SAVE to confirm upload of your
											photo
										</p>
										<p>
											or REMOVE to choose a different
											image
										</p>
										<Stack
											direction="row"
											spacing={2}
											justifyContent="center"
											margin="10px"
										>
											<Button
												type="button"
												size="small"
												variant="contained"
												disableElevation
												onClick={resetImage}
											>
												Remove
											</Button>
											<Button
												type="button"
												size="small"
												variant="contained"
												disableElevation
												onClick={handleImageUpload}
											>
												Save
											</Button>
										</Stack>
									</div>
									<div ref={confirmUploadSection}>
										<ImagePreview src={image} alt="" />
									</div>
								</div>
								<br />
								<br />
								<br />
								<br />
							</>
						)}
						{image && imageSaved && (
							<>
								<div className="addPhotoGrid">
									<section className="photo">
										<ImagePreview src={image} alt="" />
									</section>
									<section ref={captionSection}>
										<TextField
											id="outlined-basic"
											label="Your name"
											variant="outlined"
											required
											fullWidth
											onChange={handleUploadedBy}
											ref={nameRef}
										></TextField>
										<br />
										<br />
										<TextField
											id="outlined-basic"
											label="Add a caption..."
											variant="outlined"
											multiline
											required
											fullWidth
											minRows={8}
											onChange={handleCaption}
											ref={captionRef}
											aria-describedby="caption-helper-text"
										/>
										<FormHelperText id="caption-helper-text">
											Please include who's pictured, when
											the photo was taken, and, if known,
											where.
										</FormHelperText>
									</section>
								</div>
								<br />
								<br />
								<div className="tinyWrapper">
									<Button
										sx={{
											width: {
												sm: 400,
												md: 500,
												lg: 600,
											},
											height: 50,
											fontSize: "22px",
										}}
										className="submit"
										variant="contained"
										disableElevation
										disabled={!isValid}
										onClick={onFormSubmit}
									>
										Submit
									</Button>
								</div>
								<br />
								<br />
								<br />
								<br />
							</>
						)}
					</>
				)}
				{showModal && <SuccessModal showModal={showModal} />}
			</div>
		</>
	);
}

export default Photos;

//experiment with Grid and Stack in addPhotoGrid section

// import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
// import axios from "axios";
// // import { useDropzone } from 'react-dropzone';
// import Button from "@mui/material/Button";
// import TextField from "@mui/material/TextField";
// import PhotoCard from "../components/PhotoCard";
// import Pagination from "@mui/material/Pagination";
// import FormHelperText from "@mui/material/FormHelperText";
// import Grid from '@mui/material/Grid';
// import Stack from "@mui/material/Stack";
// import ImagePreview from "../components/ImagePreview";
// import SuccessModal from "../components/SuccessModal";
// import "./Photos.css";

// const kBaseUrl = "http://https://be-june-website.vercel.app/";

// const kDefaultFormState = {
// 	url: "",
// 	caption: "",
// 	uploader: "",
// };

// function Photos({ onDrop }) {

//   const [imageLoaded, setImageLoaded] = useState(false);
// 	const [addPhoto, setAddPhoto] = useState(false);
// 	const [photosData, setPhotosData] = useState([]);
// 	const [tempNewPhoto, setTempNewPhoto] = useState(kDefaultFormState);
// 	const [tempCaption, setTempCaption] = useState("");
// 	const [tempUploader, setTempUploader] = useState("");
// 	const [isValid, setIsValid] = useState(false);
// 	const [image, setImage] = useState("");
// 	const [imageSaved, setImageSaved] = useState(false);
// 	const [photoIndex, setPhotoIndex] = useState(0);
// 	const [cardList, setCardList] = useState(0);
// 	const [cardHeight, setCardHeight] = useState(0);
// 	const [page, setPage] = useState(1);
// 	const [showModal, setShowModal] = useState(false);

// 	const uploadSection = useRef();
// 	const confirmUploadSection = useRef();
// 	const captionSection = useRef();
// 	const photoRef = useRef();
// 	const nameRef = useRef();
// 	const captionRef = useRef();
// 	const cardRefs = useRef({});

// 	const getCardHeight = (index) => {
// 		setCardHeight(cardList[index].clientHeight);
// 	};

// 	const validate = () => {
// 		if (tempUploader && tempCaption) {
// 			setIsValid(true);
// 		}
// 	};

//   const onLoad = () => {
//     setImageLoaded(true);
//   }

// 	useEffect(() => {
//     // files.forEach(file => URL.revokeObjectURL(file.preview));
//     axios
//       .get(`${kBaseUrl}/images`)
//       .then((responses) => {
//         setPhotosData(responses.data);
//         setCardList(cardRefs.current);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
// 		}, [/*files*/]
// 	);

//   useLayoutEffect(() => {
// 		const timer = setTimeout(() => {
//       setCardHeight(cardRefs.current[0].clientHeight);
// 		}, 100);
// 		return () => clearTimeout(timer);
// 	}, [imageLoaded]);

// 	// send photos to cloud storage
// 	const handleImageUpload = () => {
// 		const imageCloudData = new FormData();
// 		imageCloudData.append("image", image);
// 		axios
// 			.post(`${kBaseUrl}/images/upload`, imageCloudData, {
// 				headers: {
// 					"Content-Type": "multipart/form-data",
// 				},
// 			})
// 			.then((response) => {
// 				console.log("Image URL: success");
// 				setTempNewPhoto({ ...tempNewPhoto, url: response.data.url });
// 				setImageSaved(true);
// 				scrollToAddCaption();
// 			})
// 			.catch((error) => {
// 				console.log(error);
// 			});
// 	};

// 	const resetImage = (event) => {
// 		setImage("");
// 		setImageSaved(false);
// 	};

// 	const handleJumpToUpload = (event) => {
// 		setAddPhoto(true);
// 		setTimeout(() => {
// 			uploadSection.current.scrollIntoView({
// 				behavior: "smooth",
//         block: "end",
//         inline: "nearest"
// 			});
// 		}, 100);
// 	};

// 	const handleJumpToConfirmUpload = (event) => {
// 		setTimeout(() => {
// 			confirmUploadSection.current.scrollIntoView({
// 				behavior: "smooth",
//         block: "end",
//         inline: "nearest"
// 			});
// 		}, 100);
// 	};

// 	const scrollToAddCaption = (event) => {
// 		setTimeout(() => {
// 			captionSection.current.scrollIntoView({
// 				behavior: "smooth",
// 			});
// 		}, 50);
// 	};

// 	const handleCaption = (event) => {
// 		let newCaption = event.target.value;
// 		setTempCaption(newCaption);
// 		setTempNewPhoto({ ...tempNewPhoto, caption: newCaption });
// 		validate();
// 	};

// 	const handleUploadedBy = (event) => {
// 		let uploaderName = event.target.value;
// 		setTempUploader(uploaderName);
// 		setTempNewPhoto({ ...tempNewPhoto, uploader: uploaderName });
// 		validate();
// 	};

// 	const changePhoto = (event, value) => {
//     const page = value;
// 		setPage(page);
// 		getCardHeight(page - 1);
// 		setPhotoIndex(page - 1);
// 	};

//   const photosCarousel = photosData.map((photo, n) => {
//     let position =
//       n > photoIndex
//         ? "nextCard"
//         : n === photoIndex
//         ? "activeCard"
//         : "prevCard";
//     return (
//       <li key={photo.id}>
//         <div
//           className={`photoCard ${position}`}
//           ref={(ref) => (cardRefs.current[n] = ref)}
//         >
//           <PhotoCard
//             url={photo.url}
//             caption={photo.caption}
//             uploader={photo.uploader}
//             onLoad={onLoad}
//           >
//           </PhotoCard>
//         </div>
//       </li>
//     )}
//   );

// 	const addNewPhotoToApi = (newPhoto) => {
//     const requestBody = newPhoto;

// 		axios
//     .post(`${kBaseUrl}/images`, requestBody)
// 			.then(() => {
// 				console.log("New image(s) added: success");
// 			})
// 			.catch((error) => {
// 				console.log(error);
// 			});
//     setAddPhoto(false);
//     setShowModal(true);
// 	};

// 	const onFormSubmit = (event) => {
// 		event.preventDefault();
// 		addNewPhotoToApi(tempNewPhoto);
// 	};

// 	return (
// 		<>
// 			<h1>Photos of June</h1>
// 			<div className="tinyWrapper">
//         {!addPhoto &&
//           <Button
//             sx={{
//               height: 50,
//               fontSize: "22px",
//             }}
//             variant="contained"
//             disableElevation
//             onClick={handleJumpToUpload}
//           >
//             Add a Photo
//           </Button>
//         }
// 			</div>
// 			<br />
// 			<br />
// 			<br />
// 			<div className="paginationFlex">
// 				<Pagination
// 					count={photosData.length}
// 					siblingCount={0}
// 					showFirstButton
// 					showLastButton
// 					page={page}
// 					onChange={changePhoto}
// 				/>
// 			</div>
// 			<br />
// 			<div
// 				className="cardContainerFlex"
// 			>
// 				<ul className="carousel" style={{ height: `${cardHeight + 70}px` }}>{photosCarousel}</ul>
// 			</div>
// 			<div ref={uploadSection}>
// 				{addPhoto && (
// 					<>
// 						{!image && (
//               <div className="tinyWrapper">
//                 <Button
//                   sx={{
//                     height: 50,
//                     fontSize: "22px",
//                   }}
//                   variant="contained"
//                   component="label"
//                   size="large"
//                   disableElevation
//                 >
//                   Choose a Photo
//                   <input
//                     hidden
//                     accept="image/*"
//                     multiple
//                     type="file"
//                     id="imageFile"
//                     name="imageFile"
//                     ref={photoRef}
//                     onChange={(event) => {
//                       setImage(event.target.files[0]);
//                       handleJumpToConfirmUpload();
//                     }}
//                   />
//                 </Button>
//                 <br />
//                 <br />
//                 <br />
//                 <br />
//               </div>
// 						)}
// 						{image && !imageSaved && (
// 							<>
// 								<div className="choosePhotoFlex">
// 									<div>
// 										<p>
// 											Click SAVE to confirm upload of your
// 											photo
// 										</p>
// 										<p>
// 											or REMOVE to choose a different
// 											image
// 										</p>
// 										<Stack
// 											direction="row"
// 											spacing={2}
// 											justifyContent="center"
// 											margin="10px"
// 										>
// 											<Button
// 												type="button"
// 												size="small"
// 												variant="contained"
// 												disableElevation
// 												onClick={resetImage}
// 											>
// 												Remove
// 											</Button>
// 											<Button
// 												type="button"
// 												size="small"
// 												variant="contained"
// 												disableElevation
// 												onClick={handleImageUpload}
// 											>
// 												Save
// 											</Button>
// 										</Stack>
// 									</div>
// 									<div ref={confirmUploadSection}>
// 										<ImagePreview src={image} alt="" />
// 									</div>
// 								</div>
//                 <br />
//                 <br />
//                 <br />
//                 <br />
// 							</>
// 						)}
// 						{image && imageSaved && (
// 							<>
//               	<Grid className="addPhotoGrid" container>
// 				          <Grid className="imagePreview" item xs={12} sm={6}>
//                     <section className="photo">
//                       <ImagePreview src={image} alt="" />
//                     </section>
//                   </Grid>
//                   <Grid className="addedByAndCaption" item xs={12} sm={6}>
//                     <Stack
//                         direction="column"
//                         spacing={2}
//                         justifyContent="center"
//                         margin="10px"
//                       >
//                       <section ref={captionSection}>
//                         <TextField
//                           id="outlined-basic"
//                           label="Your name"
//                           variant="outlined"
//                           required
//                           fullWidth
//                           onChange={handleUploadedBy}
//                           ref={nameRef}
//                         ></TextField>
//                         <br />
//                         <br />
//                         <TextField
//                           id="outlined-basic"
//                           label="Add a caption..."
//                           variant="outlined"
//                           multiline
//                           required
//                           fullWidth
//                           minRows={8}
//                           onChange={handleCaption}
//                           ref={captionRef}
//                           aria-describedby="caption-helper-text"
//                         />
//                         <FormHelperText id="caption-helper-text">
//                           Please include who's pictured, when
//                           the photo was taken, and, if known,
//                           where.
//                         </FormHelperText>
//                       </section>
//                     </Stack>
//                   </Grid>
//                   <br />
//                   <br />
//                   <Grid className="addedByAndCaption" item xs={12} sm={6}>
//                   {/* <div className="tinyWrapper"> */}
//                     <Button
//                       sx={{
//                         width: {
//                           sm: 400,
//                           md: 500,
//                           lg: 600,
//                         },
//                         height: 50,
//                         fontSize: "22px",
//                       }}
//                       className="submit"
//                       variant="contained"
//                       disableElevation
//                       disabled={!isValid}
//                       onClick={onFormSubmit}
//                     >
//                       Submit
//                     </Button>
//                   </Grid>
// 								</Grid>
// 								<br />
// 								<br />
// 								<br />
// 								<br />
// 							</>
// 						)}
// 					</>
// 				)}
//         {showModal &&
//           <SuccessModal showModal={showModal} />
//         }
// 			</div>
// 		</>
// 	);
// }

// export default Photos;

// dropzone code

// const getColor = (props) => {
//   if (props.isDragAccept) {
//     return '#00e676';
//   }
//   if (props.isDragReject) {
//     return '#ff1744';
//   }
//   if (props.isFocused) {
//     return '#2196f3';
//   }
//   return '#eeeeee';
// };
// const Container = styled.div`
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 40px;
//   border-width: 2px;
//   border-radius: 10px;
//   border-color: ${(props) => getColor(props)};
//   border-style: dashed;
//   background-color: #fafafa;
//   color: black;
//   font-weight: bold;
//   font-size: 1.4rem;
//   outline: none;
//   transition: border 0.24s ease-in-out;
// `;

// const {
//   getRootProps,
//   getInputProps,
//   acceptedFiles,
//   open,
//   isDragAccept,
//   isFocused,
//   isDragReject,
// } = useDropzone({
//   accept: 'image/*',
//   onDrop,
//   noClick: true,
//   noKeyboard: true,
// });
// const lists = acceptedFiles.map((list) => (
//   <li key={list.path}>
//     {list.path} - {list.size} bytes
//   </li>
// ));
/* {' '}
    <section className="dropbox">
      <Container
        className="dropbox"
        {...getRootProps({ isDragAccept, isFocused, isDragReject })}
      >
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here</p>
        <button type="button" className="btn" onClick={open}>
          Click to select file
        </button>
      </Container>
    </section>
    <aside>
      <h4>List</h4>
      <p>{lists}</p>
    </aside> */
