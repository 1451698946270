import WelcomeFooter from "./WelcomeFooter";
import "./Layout.css";


const LayoutNoNav = ({children}) => {
  return (
    <>
      <main>
        {children}
      </main>
      <WelcomeFooter />
    </>
  )
};  

export default LayoutNoNav;