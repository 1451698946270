import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import './Welcome.css';

function Welcome() {

  const navigate = useNavigate();
  const routeChange = (event) => {
    navigate('/home')
  };

  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className='welcomeFlex'>
        <div className='welcomeGrid'>
            <div className='sunriseSunsetFlex'>
              <img src='./images/6.png' alt="" />
              <p>Sunrise<br /><span className='date'>April 23, 1944</span></p>
            </div>
            <div></div>
            <div></div>
            <div className='welcomePic'></div>
            <div></div>
            <div></div>
            <div className='sunriseSunsetFlex'>
              <img src='./images/8.png' alt="" />
              <p>Sunset<br/><span className='date'>March 28, 2023</span></p>
            </div>
        </div>
        <br />
        <h1 className="welcomeMainText">Remembering<br /><span className="momName">June Stephen</span></h1>
        <div>
          <Button variant="contained" size='large' sx={{ width: '200px'}} onClick={routeChange}>Enter</Button>
        </div>
        <br />
      </div>
    </>
  );
}

export default Welcome;