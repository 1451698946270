import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import SuccessModal from "../components/SuccessModal";
import './ContactForm.css';


function ContactForm() {

  const [showModal, setShowModal] = useState(false);

  const {
    register,
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {
      name: null,
      phoneOrEmail: null,
      subject: null,
      message: null
    }
  });

  const onSubmit = async (data) => {
    const { name, phoneOrEmail, subject, message } = data;

    try {
      const templateParams = {
        name,
        phoneOrEmail,
        subject,
        message
      };
      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_PUBLIC_KEY
      );
      reset();
      setShowModal(true);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <div className='formWrapperFlex'>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { 
              m: 1,
              width: { xs: 350, sm: 342 },
              maxWidth: '100%'
            },
            '& .MuiInputLabel-root': {
              textOverflow: 'unset',
            }
          }}
          noValidate
          autoComplete="off"
        >
          <div className='tinyWrapperRow'>
            <TextField 
              type='text'
              name='name'
              id='name'
              className='name'
              label="Your Name"
              required
              {...register('name', {
                required: true
              })}
            />
            <TextField
              type='text'
              name='phoneOrEmail'
              id="phoneOrEmail"
              {...register('phoneOrEmail', {
                required: true
              })}
              className='phoneOrEmail'
              label="Your Phone or Email"
              required
            />
          </div>
        </Box>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: { xs: 350, sm: 700 }},
            '& .MuiInputLabel-root': {
              textOverflow: 'unset',
            }
          }}
          noValidate
          autoComplete="off"
        >
        <div className='tinyWrapperColumn'>            
          <TextField
              type='text'
              id="subject"
              name='subject'
              {...register('subject', {
                required: { value: true }
              })}
              className='subject'
              label="Subject"
              fullWidth
              required
            />
          </div>
          <div className='tinyWrapperColumn'>
            <TextField
              type='text'
              name='message'
              id="message"
              {...register('message', {
                required: true
              })}
              className='message'
              label="Enter Your Message Here"
              multiline
              fullWidth
              required
              rows={15}
            />
          </div>
        </Box>
        <br />
      </div>
      <div className='tinyWrapperColumn'>
        <Button
          sx={{
            width: {
              sm: 400,
              md: 500,
              lg: 600
            },
            height: 50,
            fontSize: '22px'
          }} 
          type='submit' 
          variant="contained" 
          disableElevation
          onClick={handleSubmit(onSubmit)}
          >
            Submit
        </Button>
        {showModal &&
          <SuccessModal showModal={showModal} />
        }
      </div>
    </>
  );
}

export default ContactForm;