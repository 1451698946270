import React, { useState, useEffect, useRef } from "react";
import Navbar from "./Navbar";
import Footer from "./MainFooter";
import "./Layout.css";

const LayoutWithNav = ({ children }) => {
  const [isStacked, setIsStacked] = useState(false);
  const isStackedRef = useRef(false);

  useEffect(() => {
    const handleResize = () => {
      const shouldStack = window.innerWidth < 750;
      setIsStacked(shouldStack);
      isStackedRef.current = shouldStack;
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const currentIsStacked = isStackedRef.current;
    localStorage.setItem("isStacked", JSON.stringify(currentIsStacked));
  }, [isStacked]);

  useEffect(() => {
    const storedIsStacked = localStorage.getItem("isStacked");
    if (storedIsStacked) {
      isStackedRef.current = JSON.parse(storedIsStacked);
      setIsStacked(isStackedRef.current);
    }
  }, []);


  return (
    <>
      <Navbar />
      <main className={`mainFlex ${isStacked ? "stacked" : ""}`} sx={{ flexGrow: 1, marginTop: "20px", marginBottom: "20px" }}>
        {children}
      </main>
      <Footer />
    </>
  );
};

export default LayoutWithNav;