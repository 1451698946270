export const convertTitleListToKebab = (data) => {
  let kebabData = [];
  for (let i = 0; i < data.length; i++) {
    let kebabTitle ='';
    for (let j = 0; j < data[i].length; j++) {
      if (data[i][j] !== ' ') {
        kebabTitle += data[i][j];
      } else {
        kebabTitle += '-';
      }
    }
    kebabData.push(kebabTitle);
  }
  return kebabData;
};

export const convertTitleToKebab = (data) => {
  let kebabTitle ='';
  for (let i = 0; i < data.length; i++) {
    if (data[i] !== ' ') {
      kebabTitle += data[i];
    } else {
      kebabTitle += '-';
    }
  }
  return kebabTitle;
};

export const revertTitleFromKebab = (data) => {
  let revertedTitle ='';
  for (let i = 0; i < data.length; i++) {
    if (data[i] !== '-') {
      revertedTitle += data[i];
    } else {
      revertedTitle += ' ';
    }
  }
  return revertedTitle;
};

export const trackNameOnly = (data) => {
  let trackName = [];
  for (let i = 0; i < data.length; i++) {
    const title = data[i].substring(
        data[i].indexOf("_") + 1,
        data[i].lastIndexOf(".")
      )
    trackName.push(title);
  }
  return trackName;
};