import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Link from "@mui/material/Link";
import LinkTab from "./LinkTab";
import "./Navbar.css";

function NavTabs(props) {

  const location = useLocation();

  useEffect(() => {
    setValue(getCurrentTabFromPath(location.pathname));
  }, [location.pathname]);


  const getCurrentTabFromPath = (path) => {
    if (path === '/home') return 0;
    if (path === '/memorial') return 1;
    if (path === '/photos') return 2;
    if (path === '/memories') return 3;
    if (path === '/music') return 4;
    if (path === '/contact') return 5;
    return 0; // Default value if path doesn't match any tabs
  };
  

  // const currentTab = () => {
  //   let path = window.location.pathname;
  //   if (path === "/home") {
  //     return 0;
  //   } else if (path === "/memorial") {
  //     return 1;
  //   } else if (path === "/photos") {
  //     return 2;
  //   } else if (path === "/memories") {
  //     return 3;
  //   } else if (path === "/music") {
  //     return 4;
  //   } else if (path === "/contact") {
  //     return 5;
  //   }
  // };

  const [value, setValue] = useState(0);

  return (
    <div>
      <div className="navbar">
        <div className="navbarFlex">
          <br />
          <br />
          <Link href="/home" underline="none">
            <p className="headerText">&nbsp;&nbsp;Remembering June Stephen</p>
          </Link>
          <br />
          <br />
          <br />
          <Box>
            <Tabs
              value={value}
              onChange={(event, newValue) => setValue(newValue)}
              // onChange={handleChange}
              aria-label="navigation tabs"
              centered
              TabIndicatorProps={{
                sx: {
                  "@media (max-width:749px)": {
                    backgroundColor: "white"
                  },
                },
              }}
              sx={{
                "& .MuiTabs-flexContainer": {
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { xs: "center"},
                }
              }}
            >
              <LinkTab label="Home" href="/home" />
              <LinkTab
                label="Memorial Service"
                href="/memorial"
                text="bold"
              />
              <LinkTab label="Photos" href="/photos" />
              <LinkTab label="Memories" href="/memories" />
              <LinkTab label="Musical Moments" href="/music" />
              <LinkTab label="Contact" href="/contact" />
            </Tabs>
          </Box>
        </div>
      </div>
      <br />
    </div>
  );
}

export default NavTabs;