import React from "react";
import { useNavigate } from "react-router-dom";
import Tab from '@mui/material/Tab';


function LinkTab(props) {

  const navigate = useNavigate();

  const routeChange = (event) => {
    event.preventDefault();
    navigate(props.href);
  }

  return (
    <Tab
      component="a"
      onClick={routeChange}
      {...props}
    />
  );
}

export default LinkTab;