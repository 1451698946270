import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import './MemoryCard.css';

function MemoryCard(props) {

  useEffect(() => {
    props.onCardRendered();
  }, [props]);

  const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '.5%', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

  const formattedDate = format(new Date(props.date), 'MMMM yyyy')

  return (
    <Card
      raised 
      sx={{
        margin: 'auto',
        width: "fit-content",
        maxWidth: { xs: '100%', sm: '700px' },
        minHeight: 'fit-content',
        border: '1px solid lightgrey',
      }} 
    >
      <CardContent
        sx={{ 
          width: "fit-content",
          maxWidth: { xs: '100%', sm: '675px'},
          height: 'auto',
          backgroundSize: 'contain',
          margin: 'auto',
          paddingBottom: '2% !important'
        }}
      >
        <Typography 
          variant="body1" 
          sx={{ 
            paddingLeft: "1em", 
            paddingRight: { xs: '2rem', sm: '1.5rem'}, 
            fontSize: { xs: '1rem', sm: '1.5rem'}, 
            fontFamily: 'Cormorant', 
            fontWeight: 'bolder',
            whiteSpace: "pre-line"
          }}
        >
          {props.memory}
        </Typography>
        <br />
        <br />
        <Typography 
          className='addedBy' 
          variant="body2" 
          sx={{ 
            paddingLeft: "1em", 
            paddingRight: '1em', 
            fontSize: { xs: '.75em', sm: '1em'}, 
            fontFamily: 'Cormorant', 
            fontWeight: 'bolder'
          }}
        >
          {bull} Added by {props.uploader}, {formattedDate}
        </Typography>
      </CardContent>
    </Card>
  )
}

MemoryCard.propTypes = {
  memory: PropTypes.string,
  uploader: PropTypes.string,
  date: PropTypes.string,
  onCardRendered: PropTypes.func
}

export default MemoryCard;