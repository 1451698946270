import * as React from 'react';
import ContactForm from "../components/ContactForm";
import './Contact.css';

function Contact() {

  return (
    <>
      <h1>Contact the Family</h1>
      <h2>Use the form below to contact June's husband and children</h2>
      <br />
      <br />
      <br />
      <div className='contactWrapperFlex'>
        <ContactForm />
      </div>
      <br />
      <br />
      <br />
      <br />
    </>
  );
}

export default Contact;