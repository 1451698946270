import { Outlet, Navigate } from "react-router-dom";
import { Helmet } from 'react-helmet';


import LayoutWithNav from "./components/LayoutWithNav";
import LayoutNoNav from "./components/LayoutNoNav";
import Welcome from "./pages/Welcome";
import Home from "./pages/Home";
import MemorialService from "./pages/MemorialService";
import Photos from "./pages/Photos";
import Memories from "./pages/Memories";
import MusicalMoments from "./pages/MusicalMoments";
import Contact from "./pages/Contact";
import FourOhFour from "./pages/FourOhFour";
import { createTheme, ThemeProvider } from '@mui/material/styles';


let theme = createTheme({
  palette: {
    primary: {
      main: '#b04672'
    }
  },
  breakpoints: {
    values: {
      xs: 0,      // Default: 0px
      sm: 750,    // Change the value as needed
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
})

const WelcomeLayout = () => (
  <ThemeProvider theme={theme}>
    <LayoutNoNav>
      <Outlet />
    </LayoutNoNav>
  </ThemeProvider>
)

const MainLayout = () => (
  <ThemeProvider theme={theme}>
    <LayoutWithNav>
      <Outlet />
    </LayoutWithNav>
  </ThemeProvider>
)

export const routes = [
  {
    element: <WelcomeLayout />,
    children: [
      {
        element: <Welcome />,
        path: ""
      },
    ]
  },
  {
    element: <MainLayout />,
    children: [
      {
        element: <Home />,
        path: "/home"
      },
      {
        element: <MemorialService />,
        path: "/memorial"
      },
      {
        element: <Photos />,
        path: "/photos"
      },
      {
        element: <Memories />,
        path: "/memories"
      },
      {
        element: <MusicalMoments />,
        path: "/music"
      },
      {
        element: <Contact />,
        path: "/contact"
      },
      {
        path: '/404',
        element: <FourOhFour />
      },
      {
        path: '*',
        element: <Navigate to="/404" replace />
      },
      {
        path: "/",
        element: <Navigate to="" replace />
      }
    ]
  }
]
