import React, { useEffect, useState } from "react";
import axios from 'axios';
import AudioPlayer from "../components/AudioPlayer";
import { HashLink as Link } from 'react-router-hash-link';
import { convertTitleListToKebab, convertTitleToKebab, revertTitleFromKebab } from "../utils";
import YoutubeEmbed from "../components/YoutubeEmbed";
import FloatingActionButton from "../components/FloatingActionButton";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import './MusicalMoments.css';


const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems';
const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY

function MusicalMoments() {
  
  const [youtubeData, setYoutubeData] = useState([]);

  const titleList = []
  
  useEffect(() => {
    axios
      .get(`${YOUTUBE_PLAYLIST_ITEMS_API}?part=snippet&maxResults=50&playlistId=PLsPPDIv1bMtIUWbRPCmAwCEUua7VuXxbo&key=${apiKey}`)
      .then((response) => {
        const data = response.data.items;
        setYoutubeData(data);
      });
  }, []);

  const tracks = [
    "./audio/1_Untitled.mp3",
    "./audio/2_All Through the Night.mp3",
    "./audio/3_So We'll Go No More A-roving.mp3",
    "./audio/4_My Eternal Vow.mp3",
    "./audio/5_Untitled.mp3",
    "./audio/6_Untitled.mp3",
    "./audio/7_The Road to Mandalay.mp3",
    "./audio/8_Love Walked Right In.mp3",
    "./audio/9_He's Got the Whole World in His Hands.mp3",
    "./audio/10_Alleluia.mp3",
    "./audio/11_Untitled.mp3",
    "./audio/12_Untitled.mp3",
    "./audio/13_Untitled.mp3",
    "./audio/14_Piano Duet.mp3",
    "./audio/15_English Country Garden.mp3",
    "./audio/16_A Little White Hen (Madrigal).mp3",
    "./audio/17_Joshua Fight De Battle of Jericho.mp3",
    "./audio/18_Untitled.mp3",
    "./audio/19_He's Got the Whole World in His Hands.mp3",
    "./audio/20_Christmas - Hallelujah - We Will Rock You.mp3",
    "./audio/21_Silent Night.mp3",
    "./audio/22_Christmas Medley.mp3",
  ]
  
  const videoDisplay = youtubeData.map(({ id, snippet = {} }) => {
    const { title = {}, resourceId = {} } = snippet;
    titleList.push(title);
    const kebabTitle = convertTitleToKebab(title)
    return (
      <div className='tinyVideoFlex' id={kebabTitle} key={id}>
        {title}
        <YoutubeEmbed className="iframe" embedId={resourceId.videoId}/>
      </div>
    )
  });

  const alphaTitleList = titleList.sort()
  const alphaKebab = convertTitleListToKebab(alphaTitleList);
  
  const videoTitleList = alphaKebab.map((title, i) => {
    const noHyphenTitle = revertTitleFromKebab(title);
    return (
      <li key={i}>
        <Link className='videoTitleLink' to={'/music#' + title}>{noHyphenTitle}</Link>
      </li>
    )
  })
  

  return (
    <div className='musicalMomentsWrapper'>
      <h1>Audio & Video</h1>
      <br />
      <div className='audioVideoHeader'>
        <section className='audio'>
          <Divider textAlign="left"><Chip sx={{ backgroundColor: '#e0d4da !important' }} label="AUDIO" /></Divider>
          <br />
          <p>1968 - Woodside Choir - Holiday Performance (Mrs. Ruby McGregor, Choir Director)</p>
          <br />
          <span className='center'>Select a track and click Play. Songs will continue to play in the order shown unless you click Pause or select a different track.</span>
          <br />
          <AudioPlayer tracks={tracks} />
          <br />
          <br />
          <span className='center'>If you know the name of any "Untitled" tracks, please let us know! </span>
          <Link className='link center'
            to="/contact"
            >
            Contact Us
          </Link>
          <br />
          <br />
          <p>1969 - Radio Demerara - Visit with Patricia (Pat Cameron interview)</p>
          <audio src='./audio/interview.mp3' controls/>
          <br />
          <br />
          <p>1969 - June & Randy's Wedding (Revds. Dwight V. Kyle and James F. Morton, Officiants)</p>
          <audio src='./audio/wedding.mp3' controls/>
        </section>
        <br />
        <br />
        <br />
        <section className='video'>
          <Divider textAlign="left"><Chip sx={{ backgroundColor: '#e0d4da !important' }} label="VIDEO" /></Divider>
          <br />
          <p>Jump to:</p>
          <div className="videoTitleList">
            <ul>{videoTitleList}</ul>
          </div>
          <br />
          <span>All videos courtesy of Dr. José L. Zambrana, Jr. | </span>
          <Link className='link'
            to="https://www.youtube.com/playlist?list=PLsPPDIv1bMtIUWbRPCmAwCEUua7VuXxbo"
            target="_blank"
            >
            YouTube Playlist
          </Link>
        </section>
      <br />
      <br />
      <Divider/>
      </div>
      <div className='videoGrid'>
        {videoDisplay}
      </div>
      <div className="floatingButtonFlex">
        <div className="floatingButton">
          <FloatingActionButton showBelow={800}/>
        </div>
      </div>
    </div>
  );
};


export default MusicalMoments;