import "./MainFooter.css";

const MainFooter = () => {
  return (
    <footer className="mainFooter">
      <h3 className="footerText">Anika Stephen Wilbur ©️ 2023 {'\u2022'} Made with 💗</h3>
    </footer>
  )
};

export default MainFooter;