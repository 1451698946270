import PropTypes from "prop-types";
import './ImagePreview.css';

const ImagePreview = (props) => {
  const objectUrl = URL.createObjectURL(props.src);
  return (
    <div>
      <img className="img-thumbnail" src={objectUrl} alt={props.alt} />
    </div>
  )
};

ImagePreview.propTypes = {
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  alt: PropTypes.string
};

export default ImagePreview;